import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SideBar from "./components/SideBar/SideBar";
import Home from "./components/dashboard/home/home";
import { Navigate, Route, Routes } from "react-router-dom";
import SignUp from "./components/mainPage/SignUp/SignUp";
import SignIn from "./components/mainPage/SignIn/SignIn";
import HomePage from "./components/mainPage/HomePage";
import Validator from "./components/mainPage/SignUp/Validator";
import Calls from "./components/dashboard/calls/Calls";
import Rewards from "./components/dashboard/rewards/Rewards";
import Profile from "./components/dashboard/profile/Profile";
import Services from "./components/dashboard/services/Services";
import Availability from "./components/dashboard/availability/Availability";
import Payments from "./components/dashboard/payment/Payments";
import Settings from "./components/dashboard/setting/Settings";
import Queries from "./components/dashboard/queries/Queries";
import AddingService from "./components/dashboard/services/AddingServices";
import ProfilePage from "./components/PublicProfilePage/ProfilePage";
import ServicePayment from "./components/PublicProfilePage/ServicePayment";
import BookingConfirmation from "./components/PublicProfilePage/BookingConfirmation";
import TextQuery from "./components/PublicProfilePage/TextQuery";
import SearchServiceFromDB from "./components/PublicProfilePage/SearchServiceFromDb/SearchServiceFromDB";
import PrivateRoute from "./components/PrivateRoute/PrivateRoute";
import ContactUs from "./components/contactUs";
import PrivacyPolicy from "./components/privacyPolicy";

function App() {
  return (
    <div className="Container">
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/validator" element={<Validator />} />
        <Route
          path="/dashboard/*"
          element={
            <PrivateRoute>
              <SideBar />
            </PrivateRoute>
          }
        >
          <Route path="Home" element={<Home />} />
          <Route path="Calls" element={<Calls />} />
          <Route path="rewards" element={<Rewards />} />
          <Route path="profile" element={<Profile />} />
          <Route path="services" element={<Services />} />
          <Route path="availability" element={<Availability />} />
          <Route path="payments" element={<Payments />} />
          <Route path="settings" element={<Settings />} />
          <Route path="queries" element={<Queries />} />
          <Route path="services/add" element={<AddingService />} />
          <Route path="services/edit" element={<AddingService />} />
        </Route>
        <Route path="/:username" element={<ProfilePage />} />
        <Route path="/:username/:id" element={<SearchServiceFromDB />} />
        <Route path="/:username/:id/payment" element={<ServicePayment />} />
        <Route path="/:username/:id/priority/dm" element={<TextQuery />} />
        <Route
          path="/:id/bookingConfirmation/bookingId=:bookingId"
          element={<BookingConfirmation />}
        />
        <Route path="contactUs" element={<ContactUs />} />
        <Route path="privacyPolicy" element={<PrivacyPolicy />} />
      </Routes>
      <ToastContainer />
    </div>
  );
}

export default App;
