import React, { useState } from "react";
import "./footer.css";

const Footer = () => {
    // State to manage which accordion section is open
    const [activeSection, setActiveSection] = useState(null);

    // Toggle function to open/close accordion sections
    const toggleSection = (section) => {
        setActiveSection(activeSection === section ? null : section);
    };

    return (
        <footer className="footer">
            <div className="footer-container">
                {/* Left side brand */}
                <div className="footer-left">
                    <h2 className="footer-brand">Advising Buddy</h2>
                </div>

                {/* Footer Sections - Accordion only for mobile */}
                <div className="footer-right">
                    {/* Products Section */}
                    <div className="footer-section">
                        <h4
                            onClick={() => toggleSection("products")}
                            className="footer-accordion-title"
                        >
                            Products
                        </h4>
                        <ul
                            className={`footer-accordion-content ${activeSection === "products" ? "active" : ""
                                }`}
                        >
                            <li>Series</li>
                            <li>Streams</li>
                            <li>1:1 Calls</li>
                            <li>Subscriptions</li>
                        </ul>
                    </div>

                    {/* Help Section */}
                    <div className="footer-section">
                        <h4
                            onClick={() => toggleSection("help")}
                            className="footer-accordion-title"
                        >
                            Help
                        </h4>
                        <ul
                            className={`footer-accordion-content ${activeSection === "help" ? "active" : ""
                                }`}
                        >
                            <li>Pricing</li>
                            <li>FAQ</li>
                            <li><a href="/contactUs">Contact</a></li>
                            <li>Status</li>
                            <li><a href="/privacyPolicy">Privacy Policy</a></li>

                        </ul>
                    </div>

                    {/* Explore Section */}
                    <div className="footer-section">
                        <h4
                            onClick={() => toggleSection("explore")}
                            className="footer-accordion-title"
                        >
                            Explore
                        </h4>
                        <ul
                            className={`footer-accordion-content ${activeSection === "explore" ? "active" : ""
                                }`}
                        >
                            <li>Blog</li>
                            <li>Estimate your earnings</li>
                            <li>About us</li>
                            <li>Brand assets</li>
                        </ul>
                    </div>
                </div>
            </div>

            {/* Footer bottom section with copyright and links */}
            <div className="footer-bottom">
                <div className="footer-language">
                    <i className="fas fa-globe"></i>
                    <span>English</span>
                </div>
                <div className="footer-links">
                    <ul className="pt-3">
                        <li>Security</li>
                        <li>Cookie Settings</li>
                        <li>Your Privacy Choices</li>
                    </ul>
                </div>
                <div className="footer-social">
                    <i className="fab fa-x-twitter"></i>
                    <i className="fab fa-facebook"></i>
                    <i className="fab fa-instagram"></i>
                    <i className="fab fa-linkedin"></i>
                    <i className="fab fa-youtube"></i>
                </div>
                <div className="footer-copyright">
                    © Copyright Advising Buddy 2024
                </div>
            </div>
        </footer>
    );
};

export default Footer;
